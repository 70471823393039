<template>
    <div class="main-content dashboard">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>资产统计<span>截止：{{dateFormat(date)}}</span></BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="first-block">
            <ul>
                <li><Icon type="md-basket" />资产领用<span>{{ receiving }}</span></li>
                <li><Icon type="md-briefcase" />资产派发<span>{{ distribute }}</span></li>
                <li><Icon type="md-albums" />资产调拨<span>{{ allocate }}</span></li>
                <li><Icon type="md-archive" />资产退库<span>{{ sendBack }}</span></li>
                <li><Icon type="md-pricetag" />资产借用<span>{{ borrow }}</span></li>
                <li><Icon type="md-pie" />资产归还<span>{{ giveBack }}</span></li>
                <li><Icon type="md-construct" />资产维修<span>{{ maintain }}</span></li>
                <li><Icon type="md-help-buoy" />资产保养<span>{{ repair }}</span></li>
                <li><Icon type="md-trash" />资产报废<span>{{ scrap }}</span></li>
                <li><Icon type="md-eye" />资产盘点<span>{{ isInventory }}</span></li>
            </ul>
        </div>
        <div class="second-block">
            <Row :gutter="12">
                <Col span="10">
                    <div id="myChart" class="echart1"></div>
                    <div class="echart1-option">
                        <Select size="small" v-model="id" @on-change="onChange">
                            <Option v-for="item in select" :value="item.id" :key="item.id">{{ item.name }}
                            </Option>
                        </Select>
                    </div>
                </Col>
                <Col span="14">
                    <div id="myChart2" class="echart2">echart2</div>
                </Col>
            </Row>
        </div>
        <div class="third-block">
            <div id="myChart3" class="echart3">echart3</div>
        </div>
    </div>
</template>
<script>

import {reqGetDashboard, statistic, mychartByYear} from '../api/asset-api';

export default {
    data() {
        return {
            select: [
                {
                    id: '1',
                    name: '数量'
                },
                {
                    id: '2',
                    name: '金额'
                }
            ],
            id: '1',
            date: new Date(),
            distribute: '',
            receiving: '',
            allocate: '',
            sendBack: '',
            borrow: '',
            giveBack: '',
            maintain: '',
            repair: '',
            scrap: '',
            isInventory: ''
        }
    },
    watch: {
    },
    methods: {
        getDashboard() {
            reqGetDashboard().then((res)=>{
                let dashboardArray = res.data.data;
                this.distribute = dashboardArray.filter((item)=>{
                    return item.dashboardList.includes("83");
                }).length
                this.receiving = dashboardArray.filter((item)=>{
                    return item.dashboardList.includes("85");
                }).length
                this.allocate = dashboardArray.filter((item)=>{
                    return item.dashboardList.includes("99");
                }).length
                this.sendBack = dashboardArray.filter((item)=>{
                    return item.dashboardList.includes("87");
                }).length
                this.borrow = dashboardArray.filter((item)=>{
                    return item.dashboardList.includes("89");
                }).length
                this.giveBack = dashboardArray.filter((item)=>{
                    return item.dashboardList.includes("91");
                }).length
                this.maintain = dashboardArray.filter((item)=>{
                    return item.dashboardList.includes("93");
                }).length
                this.repair = dashboardArray.filter((item)=>{
                    return item.dashboardList.includes("95");
                }).length
                this.scrap = dashboardArray.filter((item)=>{
                    return item.dashboardList.includes("43");
                }).length
                this.isInventory = dashboardArray[0].isInventory;
            }).catch(()=>{
                this.distribute = 0;
                this.receiving = 0;
                this.allocate = 0;
                this.sendBack = 0;
                this.borrow = 0;
                this.giveBack = 0;
                this.maintain = 0;
                this.repair = 0;
                this.scrap = 0;
                this.isInventory = 0;
            })
        },
        mychart(){
            var myChart = this.$echarts.init(
                document.getElementById("myChart")
            );
            statistic().then((res)=>{
                let idle = 0;
                let use = 0;
                let idleAmount = 0;
                let useAmount = 0;
                let myChartSum = 0
                res.data.data.forEach(item => {
                    if(item.status === 13 || item.status === 27 && item.statusNumber !== null && this.id === '1'){
                        myChartSum += item.statusNumber
                    }else if(item.status === 13 || item.status === 27 && item.amount !== null && this.id === '2'){
                        myChartSum += item.amount
                    }
                });
                res.data.data.forEach(item => {
                    if(item.status === 13){
                        idle = item.statusNumber;
                        idleAmount = item.amount;
                    }
                    if(item.status === 27){
                        use = item.statusNumber;
                        useAmount = item.amount;
                    }
                })
                myChart.setOption({
                    title: {
                        text: '当前资产概况',
                        textStyle: {
                            fontSize: 15
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    color: ['#028CFF', '#42B983'], // 图例及线条的颜色
                    /* 设置图例样式 */
                    legend: {
                        icon: 'circle',
                        y: 'bottom',
                        itemGap: 20, // 设置间距
                        itemWidth: 10,
                        itemHeight: 10,
                        data: ['在用','闲置'],
                        textStyle: {
                            fontSize: 14
                        },
                    },

                    graphic: [ //为环形图中间添加文字
                        {
                            type: "text",
                            left: "center",
                            top: "45%",
                            style: {
                                text: myChartSum,
                                textAlign: "center",
                                fill: "#000",
                                fontSize: 28,
                            },
                        },
                        {
                            type: "text",
                            left: "center",
                            top: "38%",
                            style: {
                                text: this.id === '1' ? '总数':'总金额',
                                textAlign: "center",
                                fill: "#999999",
                                fontSize: 14,
                            },
                        },
                    ],
                    // grid: { // 饼状图不起作用
                    //     left: 10,
                    //     right: 10,
                    //     bottom: 60,
                    //     top: 40,
                    //     containLabel: true
                    // },
                    series: [
                        {
                            // name: myChartName,
                            type: 'pie',
                            center: ['50%','50%'],
                            radius: ['35%','45%'],
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true,
                                        formatter: '{b}:{c}',// 显示百分比，
                                    },
                                    labelLine: {
                                        show :true
                                    }
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        position: 'center',
                                        textStyle: {
                                            fontSize: '14',
                                            fontWeight: 'bold'
                                        }
                                    }
                                }
                            },
                            data: [
                                {
                                    value: this.id === '1' ? use : useAmount,
                                    name: '在用'
                                },
                                {
                                    value: this.id === '1' ? idle : idleAmount,
                                    name: '闲置'
                                }
                            ]
                        }
                    ],
                });
            })
            this.$nextTick(() => {
                myChart.resize();
            });
        },
        draw(){
            var myChart2 = this.$echarts.init(
                document.getElementById('myChart2')
            );
            reqGetDashboard().then((res)=>{
                let dashboardArray = res.data.data;
                let sum = dashboardArray.length;
                console.log(sum)
                let distribute = 0;
                let receiving = 0;
                let allocate = 0;
                let sendBack = 0;
                let borrow = 0;
                let giveBack = 0;
                let maintain = 0;
                let repair = 0;
                let scrap = 0;
                let isInventory = 0;
                if(sum > 0){
                    distribute = (dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("83");
                    }).length/sum * 100).toFixed(2);
                    receiving = (dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("85");
                    }).length/sum * 100).toFixed(2);
                    allocate = (dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("99");
                    }).length/sum * 100).toFixed(2);
                    sendBack = (dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("87");
                    }).length/sum * 100).toFixed(2);
                    borrow = (dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("89");
                    }).length/sum * 100).toFixed(2);
                    giveBack = (dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("91");
                    }).length/sum * 100).toFixed(2);
                    maintain = (dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("93");
                    }).length/sum * 100).toFixed(2);
                    repair = (dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("95");
                    }).length/sum * 100).toFixed(2);
                    scrap = (dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("43");
                    }).length/sum * 100).toFixed(2);
                    isInventory = (dashboardArray[0].isInventory/sum * 100).toFixed(2);
                }
                myChart2.setOption({
                    title: {
                        text: '当前状态占比',
                        textStyle: {
                            fontSize: 15
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c}%',
                    },
                    color: ['#028CFF', '#42B983','#F16565','#0A8A8C','#DF2CBF','#64D9D6','#FF9702','#868686','#F16565','#18B9FF'], // 图例及线条的颜色
                    /* 设置图例样式 */
                    legend: {
                        type: 'scroll',
                        icon: 'circle',
                        y:'bottom',
                        itemGap: 20, // 设置间距
                        itemWidth: 10,
                        itemHeight: 10,
                        data: ['领用','派发','调拨','退库','借用','归还','保养','维修','报废','盘点'],
                        textStyle: {
                            fontSize: 14
                        },
                    },
                    // grid: {
                    //     left: 10,
                    //     right: 10,
                    //     bottom: 60,
                    //     top: 40,
                    //     containLabel: true
                    // },
                    series: [
                        {
                            name: '当前状态占比',
                            type: 'pie',
                            center: ['50%','50%'],
                            radius: ['35%','45%'],
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true,
                                        formatter: '{b}:{c}%',// 显示百分比，
                                    },
                                    labelLine: {
                                        show :true
                                    }
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        position: 'center',
                                        textStyle: {
                                            fontSize: '14',
                                            fontWeight: 'bold'
                                        }
                                    }
                                }
                            },
                            data: [
                                {value: receiving, name: '领用'},
                                {value: distribute, name: '派发'},
                                {value: allocate, name: '调拨'},
                                {value: sendBack, name: '退库'},
                                {value: borrow, name: '借用'},
                                {value: giveBack, name: '归还'},
                                {value: maintain, name: '保养'},
                                {value: repair, name: '维修'},
                                {value: scrap, name: '报废'},
                                {value: isInventory, name: '盘点'}
                            ],
                        },
                    ],
                });
            })
            this.$nextTick(() => {
                myChart2.resize();
            });
            var myChart3 = this.$echarts.init(
                document.getElementById('myChart3')
            );
            let year = this.$moment(new Date()).format('yyyy');
            mychartByYear({year: year}).then((res) => {
                let month = [];
                res.data.data.forEach((item,index) => {
                    if(index === 0){
                        month.push(item.month)
                    }else{
                        if(!month.includes(item.month))
                        month.push(item.month)
                    }
                })
                let monthArray = [];
                month.forEach(item => {
                    monthArray.push(item+'月')
                })
                let dashboardArray = res.data.data;
                let distributeArray = [];
                let receivingArray = [];
                let returningArray = [];
                let borrowArray = [];
                let giveBackArray = [];
                let repairArray = [];
                let maintainArray = [];
                let disposeArray = [];
                let allocateArray = [];
                let isInventoryArray = [];
                let sumArray = [];
                month.forEach((ele,index) => {
                    let distribute = dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("83") && JSON.stringify(item.month).includes(JSON.stringify(ele));
                    }).length;
                    distributeArray.push(distribute)

                    let receiving = dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("85") && JSON.stringify(item.month).includes(JSON.stringify(ele));
                    }).length;
                    receivingArray.push(receiving)

                    let returning = dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("87") && JSON.stringify(item.month).includes(JSON.stringify(ele));
                    }).length;
                    returningArray.push(returning)

                    let borrow = dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("89") && JSON.stringify(item.month).includes(JSON.stringify(ele));
                    }).length;
                    borrowArray.push(borrow)

                    let giveBack = dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("91") && JSON.stringify(item.month).includes(JSON.stringify(ele));
                    }).length;
                    giveBackArray.push(giveBack)

                    let repair = dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("93") && JSON.stringify(item.month).includes(JSON.stringify(ele));
                    }).length;
                    repairArray.push(repair)

                    let maintain = dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("95") && JSON.stringify(item.month).includes(JSON.stringify(ele));
                    }).length;
                    maintainArray.push(maintain)

                    let dispose = dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("97") && JSON.stringify(item.month).includes(JSON.stringify(ele));
                    }).length;
                    disposeArray.push(dispose)

                    let allocate = dashboardArray.filter((item)=>{
                        return item.dashboardList.includes("99") && JSON.stringify(item.month).includes(JSON.stringify(ele));
                    }).length;
                    allocateArray.push(allocate)
                    let isInventory = dashboardArray.filter((item)=>{
                        return JSON.stringify(item.isInventory).includes("1") && JSON.stringify(item.month).includes(JSON.stringify(ele));
                    }).length;
                    isInventoryArray.push(isInventory)
                    sumArray.push(distribute + receiving + returning + borrow + giveBack + repair + maintain + dispose + allocate + isInventory )
                })
                let maxSum = 0;
                sumArray.forEach(item => {
                    if(maxSum < item){
                        maxSum = item
                    }
                    if(maxSum%5 !== 0){
                        maxSum = maxSum + 5 - maxSum%5
                    }
                })
                myChart3.setOption({
                    title: {
                        text: '资产每月分布',
                        textStyle: {
                            fontSize: 15
                        }
                    },
                    tooltip: {
                    },
                    color: ['#028CFF', '#42B983','#F16565','#0A8A8C','#DF2CBF','#64D9D6','#FF9702','#868686','#F16565','#18B9FF','#999'], // 图例及线条的颜色
                    /* 设置图例样式 */
                    legend: {
                        icon: 'circle',
                        y:'bottom',
                        itemGap: 20, // 设置间距
                        itemWidth: 10,
                        itemHeight: 10,
                        data: ['领用','派发','调拨','退库','借用','归还','维修','保养','报废','盘点'],
                        textStyle: {
                            fontSize: 14
                        },
                    },
                    grid: {
                        left: 50,
                        right: 10,
                        bottom: 60,
                        top: 40,
                        containLabel: true
                    },
                    xAxis: [
                        {
                            data: monthArray,
                            axisLabel: {
                                textStyle: {
                                    color: '#999'
                                }
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#DCDEE2'
                                }
                            }
                        }
                    ],
                    yAxis: {
                            type: "value",
							minInterval: 1,
                            splitNumber: 5,
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: [5, 10]
                                }
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#DCDEE2"
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            max: maxSum,
                            axisLabel: {
                                show: true,
                                formatter: "{value} ",
                                color: '#262833'
                            },
                        },
                    series: [
                        {
                            name: '领用',
                            stack: 'total',
                            type: 'bar',
                            data: receivingArray,
                        },
                        {
                            name: '派发',
                            stack: 'total',
                            type: 'bar',
                            data: distributeArray,
                        },
                        {
                            name: '调拨',
                            stack: 'total',
                            type: 'bar',
                            data: allocateArray,
                        },
                        {
                            name: '退库',
                            stack: 'total',
                            type: 'bar',
                            data: returningArray,
                        },
                        {
                            name: '借用',
                            stack: 'total',
                            type: 'bar',
                            data: borrowArray,
                        },
                        {
                            name: '归还',
                            stack: 'total',
                            type: 'bar',
                            data: giveBackArray,
                        },
                        {
                            name: '维修',
                            stack: 'total',
                            type: 'bar',
                            data: repairArray,
                        },
                        {
                            name: '保养',
                            stack: 'total',
                            type: 'bar',
                            data: maintainArray,
                        },
                        {
                            name: '报废',
                            stack: 'total',
                            type: 'bar',
                            data: disposeArray,
                        },
                        {
                            name: '盘点',
                            stack: 'total',
                            type: 'bar',
                            data: isInventoryArray,
                        }
                    ],
                    barWidth: 30,
                });
            })
            this.$nextTick(() => {
                myChart3.resize();
            });
        },
        onChange(){
            this.mychart();
        },
        dateFormat(time) {
            var date=new Date(time);
            var year=date.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
            * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
            * */
            var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
            var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
            var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
            var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
            var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
            // 拼接
            return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
        }
    },
    mounted() {
        this.getDashboard();
        this.draw();
        this.mychart();
        //显示当前日期时间
        let _this = this// 声明一个变量指向Vue实例this，保证作用域一致
        this.timer = setInterval(() => {
            _this.date = new Date(); // 修改数据date
        }, 1000)
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
        }
    }
}
</script>
